import React from 'react';

import SocialSignInLanding from 'features/SocialSignIn/SocialSignInLanding';
import PublicComponent from 'navigation/PublicComponent';
import { SOCIAL_SIGNIN_PROVIDER } from 'utils/constants';

interface IGoogleLogin {
  location: Location;
}

export interface ILoginApiResponse {
  access: string;
  refresh: string;
}

const GoogleLogin: React.FC<IGoogleLogin> = ({ location }) => (
  <PublicComponent>
    <SocialSignInLanding location={location} provider={SOCIAL_SIGNIN_PROVIDER.GOOGLE_OAUTH2} />
  </PublicComponent>
);

export default GoogleLogin;
